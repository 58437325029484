<template>
  <our-team query="general-secretariat" />
</template>

<script>
import OurTeam from '@/components/OurTeam.vue';

export default {
    components: { OurTeam },
    name: "GeneralSecretariat"
}
</script>