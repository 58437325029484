<template>
  <our-team query="country-secretaries" />
</template>

<script>
import OurTeam from '@/components/OurTeam.vue';

export default {
    components: { OurTeam },
    name: "CountrySecretaries"
}
</script>