<template>
  <our-team query="partners" />
</template>

<script>
import OurTeam from '@/components/OurTeam.vue';

export default {
    components: { OurTeam },
    name: "Partners"
}
</script>